#login-container{
    background-image: linear-gradient(to left, #FFF176,#FFD54F,#FFB74D)
}
#left{
    border-right: 1px solid #E0E0E0;
}

@media (max-width:1000px) {
    #login-container{
        height:100% !important;
    }
    #rowLogin{
        padding: 5% !important;
        padding-top: 25% !important;
        padding-bottom: 35% !important;
        height:fit-content !important;
        background-image: linear-gradient(to left, #FFF176,#FFD54F,#FFB74D)
    }
    #card{
        width: 100% !important;
    }
    #left{
        border: none;
    }

}